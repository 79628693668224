import { axiosInstance, endpoints } from "./axiosHandler";

function verifyPIN(dealer, pin) {
  return new Promise((resolve, reject) => {
    axiosInstance()
      .post(endpoints.dealer + dealer.id + "/verify-pin/", { pin: pin })
      .then(response => {
        resolve(response?.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

function promote(dealer, is_manager) {
  return new Promise((resolve, reject) => {
    axiosInstance()
      .patch(endpoints.dealer + dealer.id + "/", { is_manager: is_manager })
      .then(response => {
        resolve(response?.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

function create(dealer) {
  return new Promise((resolve, reject) => {
    axiosInstance()
      .post(dealer.dealership + "dealer/", dealer)
      .then(response => {
        resolve(response?.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

function getUnalertedDealer() {
  return new Promise((resolve, reject) => {
    axiosInstance()
      .get(endpoints.dealer + "get-unalerted-dealers/")
      .then(response => {
        resolve(response?.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

function getDealerIntegrationDetails(dealer) {
  return new Promise((resolve, reject) => {
    axiosInstance()
      .get(dealer.url + "integration-details/")
      .then(response => {
        resolve(response?.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

function updateDealerIntegrationDetails(dealer) {
  return new Promise((resolve, reject) => {
    axiosInstance()
      .patch(dealer.url + "integration-details/", dealer)
      .then(response => {
        resolve(response?.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

function createIndividualDealer(dealer) {
  return new Promise((resolve, reject) => {
    axiosInstance()
      .post(endpoints.dealer + "create-individual-dealer-user/", dealer)
      .then(response => {
        resolve(response?.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

function createIndividualDealerLogin(dealer) {
  return new Promise((resolve, reject) => {
    axiosInstance()
      .post(endpoints.dealer + "create-individual-dealer-user-login/", dealer)
      .then(response => {
        resolve(response?.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

function alertIndividualDealer(dealer) {
  return new Promise((resolve, reject) => {
    axiosInstance()
      .post(dealer.url + "alert-setup-dealer/")
      .then(response => {
        resolve(response?.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

function update(dealer) {
  return new Promise((resolve, reject) => {
    axiosInstance()
      .patch(dealer.url, dealer)
      .then(response => {
        resolve(response?.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

function updatePin(dealer, pinDict) {
  return new Promise((resolve, reject) => {
    axiosInstance()
      .patch(endpoints.dealer + "update-pin/", pinDict)
      .then(response => {
        resolve(response?.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

function resetPin(dealer) {
  return new Promise((resolve, reject) => {
    axiosInstance()
      .patch(dealer.url + "reset-pin/")
      .then(response => {
        resolve(response?.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

function setCanRequestPPSR(dealer) {
  return new Promise((resolve, reject) => {
    axiosInstance()
      .patch(dealer.url, { can_request_ppsr: dealer.can_request_ppsr })
      .then(response => {
        resolve(response?.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

export {
  verifyPIN,
  promote,
  create,
  createIndividualDealer,
  createIndividualDealerLogin,
  getUnalertedDealer,
  getDealerIntegrationDetails,
  updateDealerIntegrationDetails,
  alertIndividualDealer,
  setCanRequestPPSR,
  update,
  updatePin,
  resetPin
};
