import { createStore } from "vuex";
import formStore from "./modules/forms.js";
import dealershipStore from "./modules/dealerships.js";
import authStore from "./modules/auth.js";
import resellerStore from "./modules/resellers.js";
import createPersistedState from "vuex-persistedstate";
import errorStore from "./modules/error.js";
import _ from "lodash";

export const initialStoreModules = {
  formStore,
  dealershipStore,
  authStore,
  resellerStore,
  errorStore
};

export default createStore({
  state: {},
  mutations: {
    RESET_STATE(state) {
      _.forOwn(initialStoreModules, (value, key) => {
        state[key] = _.cloneDeep(value.state);
      });
    }
  },
  actions: {
    logout(context) {
      localStorage.clear();
      sessionStorage.clear();

      context.commit("RESET_STATE");
    }
  },
  modules: _.cloneDeep(initialStoreModules),
  plugins: [createPersistedState()]
});
