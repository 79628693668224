import { update } from "../../api/Reseller.js";

const SET_RESELLER = "SET_RESELLER";

const state = {
  currentReseller: null
};

const getters = {};

const actions = {
  setCurrentReseller(context, reseller) {
    context.commit("SET_RESELLER", reseller);
  },

  updateReseller(context, { reseller, resellerDict }) {
    update(reseller, resellerDict)
      .then(response => {
        context.commit("SET_RESELLER", response);
      })
      .catch(error => {
        context.commit("errorStore/ADD_ERROR", error, { root: true });
      });
  }
};

const mutations = {
  [SET_RESELLER](state, currentReseller) {
    state.currentReseller = currentReseller;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
