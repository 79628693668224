import { axiosInstance, apiConstants, endpoints } from "./axiosHandler";

function login(loginData, forSession = false) {
  return new Promise((resolve, reject) => {
    axiosInstance()
      .post(apiConstants.api_auth_hostname + "login/", loginData, { withCredentials: forSession })
      .then(response => {
        resolve(response?.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

function logout() {
  return new Promise((resolve, reject) => {
    axiosInstance()
      .post(apiConstants.api_auth_hostname + "rest-logout/")
      .then(response => {
        resolve(response?.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

function generatePIN() {
  return new Promise((resolve, reject) => {
    axiosInstance()
      .post(endpoints.dealer + "generate-pin/")
      .then(response => {
        resolve(response?.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

function claimDealer(data) {
  return new Promise((resolve, reject) => {
    axiosInstance()
      .post(endpoints.dealer + "claim-dealer/", data)
      .then(response => {
        resolve(response?.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

function requestResetPassword(email) {
  return new Promise((resolve, reject) => {
    axiosInstance()
      .post(apiConstants.api_auth_hostname + "password/reset/", {
        email: email
      })
      .then(response => {
        resolve(response?.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

function resetPassword(data) {
  return new Promise((resolve, reject) => {
    axiosInstance()
      .post(apiConstants.api_auth_hostname + "password/reset/confirm/", data)
      .then(response => {
        resolve(response?.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

export { login, logout, generatePIN, claimDealer, requestResetPassword, resetPassword };
