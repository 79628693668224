import { axiosInstance, endpoints } from "./axiosHandler";

function fetchDealership() {
  return new Promise((resolve, reject) => {
    axiosInstance()
      .get(endpoints.dealership, null)
      .then(response => {
        resolve(response?.data?.results);
      })
      .catch(error => {
        reject(error);
      });
  });
}

function fetchDealershipWithId(dealership) {
  return new Promise((resolve, reject) => {
    axiosInstance()
      .get(dealership.url, null)
      .then(response => {
        resolve(response?.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

function updateDealership(dealership, name) {
  return new Promise((resolve, reject) => {
    axiosInstance()
      .patch(dealership.url, { first_name: name })
      .then(response => {
        resolve(response?.data?.results);
      })
      .catch(error => {
        reject(error);
      });
  });
}

function createDepartment(department) {
  return new Promise((resolve, reject) => {
    axiosInstance()
      .post(endpoints.department, department)
      .then(response => {
        resolve(response?.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

function updateDepartment(department, title) {
  return new Promise((resolve, reject) => {
    axiosInstance()
      .patch(department.url, { title: title })
      .then(response => {
        resolve(response?.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

function deleteDepartment(department) {
  return new Promise((resolve, reject) => {
    axiosInstance()
      .delete(department.url)
      .then(response => {
        resolve(response?.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

function fetchDealershipsPPSRStatus() {
  return new Promise((resolve, reject) => {
    axiosInstance()
      .get(endpoints.dealership + "ppsr/", null)
      .then(response => {
        resolve(response?.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

function updateDealershipPPSRStatus(dealership, ppsr_status) {
  return new Promise((resolve, reject) => {
    axiosInstance()
      .patch(dealership.url + "ppsr/", { ppsr_status: ppsr_status })
      .then(response => {
        resolve(response?.data?.results);
      })
      .catch(error => {
        reject(error);
      });
  });
}

function pauseFavourite(reseller) {
  return new Promise((resolve, reject) => {
    axiosInstance()
      .post(reseller.url + "pause/")
      .then(response => {
        resolve(response?.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

function activateFavourite(reseller) {
  return new Promise((resolve, reject) => {
    axiosInstance()
      .post(reseller.url + "activate/")
      .then(response => {
        resolve(response?.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

export {
  fetchDealership,
  fetchDealershipWithId,
  createDepartment,
  updateDepartment,
  deleteDepartment,
  updateDealership,
  fetchDealershipsPPSRStatus,
  updateDealershipPPSRStatus,
  pauseFavourite,
  activateFavourite
};
