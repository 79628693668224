import { dealerName } from "./dealerHelpers.js";
import state from "../store/index.js";
import moment from "moment";

function isDealership() {
  return state.state.authStore.isDealership;
}

function isNZ() {
  return (
    (state.state.authStore.isDealership && state.state.dealershipStore.currentDealership.country == "NZ") ||
    (state.state.authStore.isReseller && state.state.resellerStore.currentReseller.country == "NZ")
  );
}

function isManager() {
  return state.state.dealershipStore.currentDealer ? state.state.dealershipStore.currentDealer.is_manager : false;
}

function editableCheck() {
  return (
    isManager() ||
    (state.state.authStore.isDealership &&
      state.state.dealershipStore.currentDealership.sales_users_can_edit_from_website)
  );
}

function dealershipData(form) {
  return {
    title: "Dealership",
    editable: editableCheck(),
    data: [
      {
        title: "Dealership",
        content: form.dealership.name
      },
      {
        title: "Started By",
        content: dealerName(form.starting_dealer),
        isHidden: !isDealership(),
        type: "dealer",
        key: "starting_dealer"
      },
      {
        title: "Completed By",
        content: dealerName(form.dealer)
      },
      {
        title: "Winner",
        content: form.winning_offer
          ? form.winning_offer.reseller
            ? form.winning_offer.reseller.first_name + " " + form.winning_offer.reseller.last_name
            : form.winning_offer.custom_name
          : "—",
        isHidden: !isDealership() || !isManager()
      },
      {
        title: "Initial Appraisal Date",
        content: moment(new Date(form.date_time_created))?.format("DD-MMM-YYYY hh:mm a")
      },
      {
        title: "Source",
        content: form.source
      },
      {
        title: "Department",
        content: form.department ? form.department.title : null,
        key: "department",
        type: "department"
      },
      {
        title: "Previously Appraised",
        content:
          "This vehicle has previously been appraised on " +
          moment(new Date(form.previous_appraisal?.date_time_created))?.format("DD-MMM-YYYY") +
          " (click to view)",
        isHidden: !form.previous_appraisal || !isManager(),
        type: "textarea",
        onClick: props => {
          let routeData = props.resolve({
            name: "Form",
            params: { id: form.previous_appraisal.id }
          });
          window.open(routeData.href, "_blank");
        }
      }
    ]
  };
}

function customerInformationData() {
  if (isDealership()) {
    return {
      title: "Customer Information",
      editable: true,
      data: [
        {
          title: "Name",
          key: "customer_name"
        },
        {
          title: "Address",
          key: "customer_address"
        },
        {
          title: "Postcode",
          key: "customer_postcode",
          type: "number"
        },
        {
          title: "Home Phone",
          key: "customer_home_phone"
        },
        {
          title: "Mobile Phone",
          key: "customer_work_phone"
        },
        {
          title: "Email",
          key: "customer_email"
        },
        {
          title: "Registration Entity",
          key: "registration_entity"
        }
      ]
    };
  } else {
    return { data: [] };
  }
}

function carInformationData(form) {
  return {
    title: "Car Information",
    wide: true,
    editable: editableCheck(),
    data: [
      {
        title: "Recall Status",
        content: form.takata_status ? (!form.takata_status.is_clear ? form.takata_status.message : "None") : "None",
        is_error: form.takata_status ? !form.takata_status.is_clear : false,
        type: form.takata_status ? (form.takata_status.is_clear ? "text" : "textarea") : "text"
      },
      {
        title: "Make",
        key: "car_make"
      },
      {
        title: "Model",
        key: "car_model"
      },
      {
        title: "Model Year",
        key: "car_model_year",
        type: "number"
      },
      {
        title: "Model Code",
        key: "car_model_code"
      },
      {
        title: "Redbook Code",
        key: "redbook_code",
        isHidden: !isDealership()
      },
      {
        title: "Line",
        key: "car_line"
      },
      {
        title: "Series",
        key: "car_series"
      },
      {
        title: "Body",
        key: "car_body"
      },
      {
        title: "Fuel Type",
        key: "car_fuel"
      },
      {
        title: "Travelled",
        key: "car_mileage"
      },
      {
        title: "Transmission",
        content: form.car_transmission != null ? (form.car_transmission ? "Automatic" : "Manual") : "—",
        isHidden: form.car_is_authorised || form.car_is_authorised_rego
      },
      {
        title: "Compliance Date",
        content: (() => {
          var date = null;
          var addStar = false;
          if (form.car_compliance_date) {
            date = form.car_compliance_date;
            if (form.ppsr) {
              addStar = true;
            }
          } else if (form.ppsr) {
            date = form.ppsr.car_compliance_date ? form.ppsr.car_compliance_date : "—";
          }
          if (date) {
            let dateAsDate = new Date(date);
            if (dateAsDate && !isNaN(dateAsDate.getTime())) {
              return moment(dateAsDate).format("MMM-YYYY") + (addStar ? " (*)" : "");
            } else {
              return date;
            }
          }
        })(),
        key: "car_compliance_date",
        isHidden: isNZ(),
        type: "date"
      },
      {
        title: "Build Date",
        key: "car_build_date",
        content: (() => {
          if (form.car_build_date) {
            let dateAsDate = new Date(form.car_build_date);
            if (dateAsDate && !isNaN(dateAsDate.getTime())) {
              return moment(dateAsDate).format("MMM-YYYY");
            }
          }
          return null;
        })(),
        type: "date"
      },
      {
        title: "Year of Manufacture",
        key: "car_year_of_manufacture",
        content: (() => {
          if (form.ppsr) {
            return form.ppsr.car_year_of_manufacture
              ? form.ppsr.car_year_of_manufacture
              : form.car_year_of_manufacture
              ? form.car_year_of_manufacture
              : "—";
          }
          return form.car_year_of_manufacture ? form.car_year_of_manufacture : "—";
        })(),
        type: "number"
      },
      {
        title: "Vehicle Colour",
        content: (() => {
          if (form.ppsr) {
            return form.ppsr.car_colour ? form.ppsr.car_colour : form.car_colour ? form.car_colour : "—";
          }
          return form.car_colour ? form.car_colour : "—";
        })(),
        key: "car_colour"
      },
      {
        title: "Trim Colour",
        key: "car_trim"
      },
      {
        title: "Front Tread",
        key: "car_front_tread",
        type: "number"
      },
      {
        title: "Rear Tread",
        key: "car_rear_tread",
        type: "number"
      },
      {
        title: "Is Imported",
        content: booleanString(form.is_import),
        isHidden: !isNZ()
      },
      {
        title: "Road users charge expiry",
        content: form.ruc_expiry ? form.ruc_expiry : "—",
        isHidden: !isNZ()
      },
      {
        title: "Registration number",
        key: "car_registration_number"
      },
      {
        title: isNZ() ? "First Registration Date" : "Registration expiry",
        key: "car_registration_expiry",
        type: "date",
        content: (() => {
          var date = null;
          if (form.ppsr) {
            date = form.ppsr.car_registration_expiry
              ? form.ppsr.car_registration_expiry
              : form.car_registration_expiry
              ? form.car_registration_expiry
              : "—";
          } else {
            date = form.car_registration_expiry ? form.car_registration_expiry : "—";
          }
          let dateAsDate = new Date(date);
          if (dateAsDate && !isNaN(dateAsDate.getTime())) {
            return moment(dateAsDate).format("DD-MMM-YYYY");
          } else {
            return date;
          }
        })()
      },
      {
        title: "Registration state or territory",
        content: (() => {
          if (form.ppsr) {
            return form.ppsr.car_registration_state
              ? form.ppsr.car_registration_state
              : form.car_registration_state
              ? form.car_registration_state
              : "—";
          }
          return form.car_registration_state ? form.car_registration_state : "—";
        })(),
        isHidden: isNZ()
      },
      {
        title: "Stock number",
        key: "car_stock_number",
        isHidden: !isDealership()
      },
      {
        title: "VIN number",
        key: "car_chassis_number"
      },
      {
        title: "Engine number",
        key: "car_engine_number",
        content: (() => {
          if (form.ppsr) {
            return form.ppsr.car_engine_number
              ? form.ppsr.car_engine_number
              : form.car_engine_number
              ? form.car_engine_number
              : "—";
          }
          return form.car_engine_number ? form.car_engine_number : "—";
        })()
      },
      {
        title: "Seat count",
        key: "car_seat_count",
        type: "number"
      },
      {
        title: "Carpets",
        content: qualityString(form.car_carpets),
        key: "car_carpets",
        type: "quality"
      },
      {
        title: "Roof lining",
        content: qualityString(form.car_roof_lining),
        key: "car_roof_lining",
        type: "quality"
      },
      {
        title: "Seat quality",
        content: qualityString(form.car_seat_quality),
        key: "car_seat_quality",
        type: "quality"
      },
      {
        title: "Door trims",
        content: qualityString(form.car_door_trims),
        key: "car_door_trims",
        type: "quality"
      },
      {
        title: "Car fascia",
        content: qualityString(form.car_fascia),
        key: "car_fascia",
        type: "quality"
      },
      {
        title: "Car boot",
        content: qualityString(form.car_boot),
        key: "car_boot",
        type: "quality"
      },
      {
        title: "Wheel size",
        key: "car_wheel_size",
        type: "number"
      },
      {
        title: "Currently encumbered",
        content: booleanString(form.is_currently_encumbered)
      },
      {
        title: "Service history quality",
        key: "service_history_quality",
        content: qualityString(form.service_history_quality),
        type: "quality"
      },
      {
        title: "Service history comments",
        key: "service_history_comments"
      },
      {
        title: "Spare Keys",
        key: "car_spare_keys",
        type: "number"
      },
      {
        title: "Imported From",
        key: "imported_from",
        isHidden: !form.is_import
      },
      {
        title: "Warrant of fitness expiry",
        key: "wof_expiry",
        isHidden: !isNZ()
      }
    ]
  };
}

function financialInformationData(form) {
  if (isDealership()) {
    return {
      title: "Financial Information",
      editable: editableCheck(),
      wide: true,
      data: [
        {
          title: "Approximate payout",
          key: "approx_payout",
          content: form.approx_payout ? "$" + form.approx_payout : null,
          type: "number"
        },
        {
          title: "Target repayment monthly",
          key: "monthly_repayment",
          content: form.monthly_repayment ? "$" + form.monthly_repayment : null,
          type: "number"
        },
        {
          title: "Type of new car customer interested in",
          key: "new_car_interested_in"
        },
        {
          title: "Target monthly repayment on new purchase",
          key: "target_monthly_payment_on_new_purchase",
          content: form.target_monthly_payment_on_new_purchase
            ? "$" + form.target_monthly_payment_on_new_purchase
            : null,
          type: "number"
        },
        {
          title: "Finance requested",
          content: booleanString(form.is_finance_requested),
          key: "is_finance_requested",
          type: "checkbox"
        },
        {
          title: "Financier",
          key: "financier"
        },
        {
          title: "Car traded",
          content: booleanString(form.winner || form.custom_winner)
        },
        {
          title: "Date appraisal completed",
          content: form.earliest_offer
        },
        {
          title: isManager() ? "Appraisal value sent to sales staff" : "Appraisal Value",
          key: "sales_value",
          type: "number",
          content: form.sales_value ? "$" + form.sales_value : null
        },
        {
          title: "Offer Valid Date",
          key: "offer_valid_date",
          type: "date",
          content: form.offer_valid_date ? moment(new Date(form.offer_valid_date))?.format("DD-MMM-YYYY") : "—",
          isHidden: !isDealership()
        },
        {
          title: "Retail Value",
          key: "retail_value",
          type: "number",
          content: form.retail_value ? "$" + form.retail_value : null
        }
      ]
    };
  } else {
    return { data: [] };
  }
}

function ppsrInformationYesNo(value) {
  return value ? "Yes" : "No";
}

function ppsrInformationData(form) {
  var requestDate;
  var requestDateUpdated;

  if (form.ppsr) {
    requestDate = new Date(form.ppsr.request_date);
    requestDateUpdated = new Date(form.ppsr.request_date_updated);
  }
  return {
    title: "PPSR Information",
    editable: false,
    wide: true,
    isPpsr: true,
    data: [
      {
        title: "Encumbered",
        content: form.ppsr ? ppsrInformationYesNo(form.ppsr.encumbered) : "—",
        is_error: form.ppsr ? form.ppsr.encumbered : false
      },
      {
        title: "Written off",
        content: form.ppsr ? ppsrInformationYesNo(form.ppsr.written_off) : "—",
        is_error: form.ppsr ? form.ppsr.written_off : false
      },
      {
        title: "Stolen",
        content: form.ppsr ? ppsrInformationYesNo(form.ppsr.stolen) : "—",
        is_error: form.ppsr ? form.ppsr.stolen : false
      },
      {
        title: "Requested by",
        content: form.ppsr ? dealerName(form.ppsr.requested_by) : "—"
      },
      {
        title: "Request date",
        content: form.ppsr ? `${requestDate.toDateString()}, ${requestDate.toLocaleTimeString()}` : "—"
      },
      {
        title: "Last update check date",
        content: form.ppsr
          ? form.ppsr.request_date_updated
            ? `${requestDateUpdated.toDateString()}, ${requestDateUpdated.toLocaleTimeString()}`
            : "—"
          : "—"
      }
    ]
  };
}

function specObjectData(form) {
  if ((form.spec_objects && form.spec_objects.length > 0) || form.merc_pack || form.bmw_pack || form.audi_pack) {
    var data = [];
    form.spec_objects.forEach(specObject => {
      data.push({ title: specObject.description, content: specObject.value ? "Yes" : "No" });
    });

    if (form.merc_pack) {
      data.push({ title: "Mercedes-Benz", content: form.merc_pack });
    }
    if (form.bmw_pack) {
      data.push({ title: "BMW", content: form.bmw_pack });
    }
    if (form.audi_pack) {
      data.push({ title: "Audi", content: form.audi_pack });
    }

    return {
      title: "Additional Information",
      editable: false,
      data: data
    };
  } else {
    return { title: "No Additional Information", editable: false, data: [] };
  }
}

function qualityString(qualityInt) {
  if (qualityInt == null) {
    return "—";
  }
  switch (qualityInt) {
    case "0":
      return "Bad";
    case "1":
      return "Average";
    case "2":
      return "Good";
    case "3":
      return "Full Dealership Service";
    default:
      return "—";
  }
}

function tradedPurposeString(tradedPurpose) {
  if (tradedPurpose == null) {
    return "—";
  }
  switch (tradedPurpose) {
    case 0:
      return "Wholesale";
    case 1:
      return "Retail";
    case 2:
      return "Undecided";
    case 3:
      return "Budget";
    default:
      return "—";
  }
}

function etaString(form) {
  if (form == null) {
    return "—";
  }
  return form.arrived ? "Arrived" : form.traded_eta ? new Date(form.traded_eta).toDateString() : "—";
}

function booleanString(booleanBoolean) {
  if (booleanBoolean == null) {
    return "—";
  }
  return booleanBoolean ? "Yes" : "No";
}

function tenderHasEnded(tender_end_time) {
  if (tender_end_time) {
    const endDate = new Date(tender_end_time);
    const now = new Date();
    return endDate.getTime() < now.getTime();
  } else {
    return false;
  }
}

function tenderHasStarted(tender_start_time) {
  if (tender_start_time) {
    const startDate = new Date(tender_start_time);
    const now = new Date();
    return startDate.getTime() < now.getTime();
  } else {
    return false;
  }
}

const damageTypes = [
  { name: "Dent", colour: "#f6153a" },
  { name: "Scratch", colour: "#f6153a" },
  { name: "Chip", colour: "#39be0b" },
  { name: "Rust", colour: "#ff9f02" },
  { name: "Respray", colour: "#3a53e0" },
  { name: "Crack", colour: "#b226bf" },
  { name: "SmallDent", colour: "#25710a" },
  { name: "LargeDent", colour: "#53ff0c" },
  { name: "Previous", colour: "#31bbbf" },
  { name: "Loose", colour: "#afb42b" },
  { name: "Missing", colour: "#dce775" }
];

const reasonsLost = [
  "Selling Privately",
  "Wants More Money/Negative Equity",
  "Didn't Trade But Bought New Vehicle",
  "Bought Elsewhere",
  "Duplicate Appraisal",
  "Test Appraisal",
  "Not Purchasing"
];

export {
  dealershipData,
  customerInformationData,
  carInformationData,
  financialInformationData,
  ppsrInformationData,
  specObjectData,
  tradedPurposeString,
  etaString,
  tenderHasEnded,
  tenderHasStarted,
  damageTypes,
  reasonsLost
};
