import { axiosInstance, endpoints } from "./axiosHandler";

function requestFormlessPPSR(vin, newRequest = false) {
  return axiosInstance().post(endpoints.ppsr, {
    car_chassis_number: vin,
    new_request: newRequest
  });
}

function requestFormlessPPSRPDF(ppsrId) {
  return axiosInstance().get(`${endpoints.ppsr}${ppsrId}/request-ppsr-pdf/`);
}

function acceptTnCs() {
  return new Promise((resolve, reject) => {
    axiosInstance()
      .get(endpoints.ppsr + "accept-tnc/", null)
      .then(response => {
        resolve(response?.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

export { requestFormlessPPSR, requestFormlessPPSRPDF, acceptTnCs };
