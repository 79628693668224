import { axiosInstance, endpoints } from "./axiosHandler";
import store from "../store/index.js";

function getReports(filters, cancelToken) {
  return new Promise((resolve, reject) => {
    axiosInstance()
      .get(endpoints.reports, {
        params: filters,
        cancelToken: cancelToken.token
      })
      .then(response => {
        resolve(response?.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

function createReportFilter(title, filterString, description) {
  return new Promise((resolve, reject) => {
    axiosInstance()
      .post(store.state.dealershipStore.currentDealership.url + "create-report-filter/", {
        title: title,
        filter_string: filterString,
        description: description
      })
      .then(response => {
        resolve(response?.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

function fetchReportFilters() {
  return new Promise((resolve, reject) => {
    axiosInstance()
      .get(endpoints.reportFilters)
      .then(response => {
        resolve(response?.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

function deleteReportFilters(filter) {
  return new Promise((resolve, reject) => {
    axiosInstance()
      .delete(filter.url)
      .then(response => {
        resolve(response?.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

export { getReports, createReportFilter, fetchReportFilters, deleteReportFilters };
