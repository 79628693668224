<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: "App"
};
</script>

<style scss>
/* Include sf-pro font */
@import "~@/fonts/sf-pro/stylesheet.scss";
</style>
