import { fetchForms, startTender, downloadCSV } from "../../api/Form.js";
import { createReportFilter } from "../../api/Reports.js";
import axios from "axios";
import indexState from "../index.js";
import moment from "moment";
import { calculateFilters } from "../../helpers/filterHelpers.js";

const state = {
  forms: {},
  filters: {},
  currentUrl: null,
  nextUrl: null,
  totalFormsCount: null,
  axiosCancelToken: null,
  loadingForms: false
};

const getters = {
  getCurrentUrl: state => {
    return state.currentUrl;
  },
  getLoadingForms: state => {
    return state.loadingForms;
  },
  getFilters: state => {
    return state.filters;
  },
  getForm: state => id => {
    return state.forms[id];
  },
  getForms: state => () => {
    var toReturn = [];
    for (const [, value] of Object.entries(state.forms)) {
      toReturn.push(value);
    }
    return toReturn.sort((a, b) => {
      if (a.date_time_created) {
        return b.date_time_created > a.date_time_created ? 1 : -1;
      } else {
        return b.id - a.id;
      }
    });
  },
  getNextForm: state => id => {
    const keys = Object.keys(state.forms);
    const index = keys.findIndex(formId => formId == id);

    if (index + 1 >= keys.length) {
      return null;
    } else {
      return state.forms[keys[index + 1]];
    }
  },
  getPreviousForm: state => id => {
    const keys = Object.keys(state.forms);
    const index = keys.findIndex(formId => formId == id);

    if (index - 1 < 0) {
      return null;
    } else {
      return state.forms[keys[index - 1]];
    }
  },
  getNextUrl: state => {
    return state.nextUrl;
  }
};

const SET_CURRENT_URL = "SET_CURRENT_URL";
const SET_FORMS_DATA = "SET_FORMS_DATA";
const SET_NEXT_URL = "SET_NEXT_URL";
const SET_FRESH_FORMS_DATA = "SET_FRESH_FORMS_DATA";
const SET_FILTERS = "SET_FILTERS";
const ADD_FORMS = "ADD_FORMS";
const START_TENDER = "START_TENDER";

const actions = {
  setCurrentUrl(context, url) {
    context.commit(SET_CURRENT_URL, url);
  },
  getForms(context) {
    getForms(context, context.state.currentUrl, true);
  },
  getMoreForms(context) {
    if (context.state.nextUrl) {
      getForms(context, context.state.nextUrl, false);
    }
  },
  cancelRequest(context) {
    if (context.state.axiosCancelToken) {
      if (context.state.axiosCancelToken.cancel) {
        context.state.axiosCancelToken.cancel("Request Cancelled");
        context.state.loadingForms = false;
      }
    }
  },
  addForms(context, forms) {
    context.commit(ADD_FORMS, forms);
  },
  addFilter(context, { filter }) {
    var filterDict = context.state.filters;
    filterDict[filter.key] = filter.value;
    context.commit(SET_FILTERS, filterDict);
    getForms(context, context.state.currentUrl, true);
  },
  removeFilter(context, { filter }) {
    var filterDict = context.state.filters;
    delete filterDict[filter.key];
    context.commit(SET_FILTERS, filterDict);
    getForms(context, context.state.currentUrl, true);
  },
  removeAllFilters(context) {
    var filterDict = context.state.filters;
    var validKeys = ["date_time_created_gt", "date_time_created_lt"];
    Object.keys(filterDict).forEach(key => validKeys.includes(key) || delete filterDict[key]);
    context.commit(SET_FILTERS, filterDict);
    getForms(context, context.state.currentUrl, true);
  },
  setOneMonthFilter(context) {
    var filterDict = context.state.filters;
    filterDict["date_time_created_gt"] = moment().subtract(14, "d").format("yyyy-MM-DD");
    filterDict["date_time_created_lt"] = moment().format("yyyy-MM-DD");
    context.commit(SET_FILTERS, filterDict);
  },
  startTender(context, timeDict) {
    startTender(timeDict)
      .then(() => {
        context.commit(START_TENDER, timeDict);
      })
      .catch(error => {
        if (!error.__CANCEL__) {
          context.commit("errorStore/ADD_ERROR", error, { root: true });
        }
      });
  },

  setForms(context, forms) {
    context.commit(SET_FRESH_FORMS_DATA, {
      results: forms,
      next: null,
      count: forms.length
    });
  },

  setNextUrl(context, nextUrl) {
    context.commit(SET_NEXT_URL, nextUrl);
  },

  createReportFilter(context, title) {
    var filterDict = context.state.filters;
    var filterString = "?";
    for (const [key, value] of Object.entries(filterDict)) {
      if (key != "date_time_created_gt" && key != "date_time_created_lt") {
        filterString += key + "=" + value + "&";
      }
    }

    var description = "";
    calculateFilters(
      filterDict,
      indexState.state.dealershipStore.dealerships,
      indexState.getters["dealershipStore/dealers"]
    ).forEach(function (filter, i, array) {
      description += filter.title + ": " + filter.value;
      if (i != array.length - 1) {
        description += ", ";
      }
    });

    createReportFilter(title, filterString, description).catch(error => {
      context.commit("errorStore/ADD_ERROR", error, { root: true });
    });
  },

  downloadCSV(context, url) {
    return new Promise(resolve => {
      downloadCSV(url, context.state.filters)
        .then(response => {
          var blob = new Blob([response.data], { type: "csv" });
          var link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "report.csv";
          link.click();
          resolve();
        })
        .catch(error => {
          context.commit("errorStore/ADD_ERROR", error, { root: true });
          resolve();
        });
    });
  }
};

function getForms(context, url, refreshData) {
  if (context.state.axiosCancelToken) {
    if (context.state.axiosCancelToken.cancel) {
      context.state.axiosCancelToken.cancel("New Request");
    }
  }
  context.state.axiosCancelToken = axios.CancelToken.source();
  context.state.loadingForms = true;
  if (refreshData) {
    context.commit(SET_FRESH_FORMS_DATA, { results: [] });
  }
  fetchForms(url, refreshData ? context.state.filters : {}, context.state.axiosCancelToken)
    .then(response => {
      context.state.loadingForms = false;
      if (response) {
        if (refreshData) {
          context.commit(SET_FRESH_FORMS_DATA, response);
        } else {
          context.commit(SET_FORMS_DATA, response);
        }
      }
    })
    .catch(error => {
      if (!error.__CANCEL__) {
        context.state.loadingForms = false;
        context.commit("errorStore/ADD_ERROR", error, { root: true });
      }
    });
}

const mutations = {
  [SET_CURRENT_URL](state, url) {
    state.currentUrl = url;
  },

  [SET_FORMS_DATA](state, data) {
    setFormData(false, state, data);
  },

  [SET_NEXT_URL](state, url) {
    state.nextUrl = url;
  },

  [SET_FRESH_FORMS_DATA](state, data) {
    setFormData(true, state, data);
  },

  [SET_FILTERS](state, filters) {
    state.filters = filters;
  },

  [ADD_FORMS](state, forms) {
    forms.forEach(form => {
      state.forms[form.id] = form;
    });
  },
  [START_TENDER](state, dateDict) {
    for (const [key, value] of Object.entries(state.forms)) {
      if (value.appraisal_status == "Pending") {
        if (!value.tender_start_time) {
          if (value.dealership.url == indexState.state.dealershipStore.currentDealership.url) {
            state.forms[key].tender_start_time = dateDict.start_time;
            state.forms[key].tender_end_time = dateDict.end_time;
          }
        }
      }
    }
  }
};

function setFormData(fresh, state, data) {
  if (fresh) {
    state.forms = {};
  }
  data.results.forEach(form => {
    state.forms[form.id] = form;
  });
  state.nextUrl = data.next;
  state.totalFormsCount = data.count;
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
