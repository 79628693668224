const state = {
  errors: []
};

const getters = {
  currentError: state => {
    if (state.errors.length > 0) {
      return state.errors[state.errors.length - 1];
    }
    return null;
  },
  errors: state => {
    return state.errors;
  }
};

const ADD_ERROR = "ADD_ERROR";
const REMOVE_ERROR = "REMOVE_ERROR";
const REMOVE_ALL_ERRORS = "REMOVE_ALL_ERRORS";

const actions = {
  addError(context, error) {
    context.commit(ADD_ERROR, error);
  },
  removeError(context, index) {
    context.commit(REMOVE_ERROR, index);
  },
  removeAllErrors(context) {
    context.commit(REMOVE_ALL_ERRORS);
  }
};

const mutations = {
  [ADD_ERROR](state, error) {
    state.errors.push(error);
  },
  [REMOVE_ERROR](state, index) {
    state.errors.splice(index, 1);
  },
  [REMOVE_ALL_ERRORS](state) {
    state.errors = [];
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
