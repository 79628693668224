const state = {
  authToken: null,
  dealerToken: null,
  isDealership: null,
  isSuperUser: false
};

const getters = {
  isLoggedIn: state => {
    return state.authToken != null;
  },

  isDealership: state => {
    return state.isDealership;
  }
};

const actions = {
  setAuthToken(context, authToken) {
    context.commit("SET_AUTH_TOKEN", authToken);
  },

  setIsDealership(context, isDealership) {
    context.commit("SET_IS_DEALERSHIP", isDealership);
  },

  setIsSuperUser(context, isSuperUser) {
    context.commit("SET_IS_SUPER_USER", isSuperUser);
  }
};

const mutations = {
  SET_AUTH_TOKEN(state, authToken) {
    state.authToken = authToken;
  },

  SET_DEALER_TOKEN(state, dealerToken) {
    state.dealerToken = dealerToken;
  },

  SET_IS_DEALERSHIP(state, isDealership) {
    state.isDealership = isDealership;
  },

  SET_IS_SUPER_USER(state, isSuperUser) {
    state.isSuperUser = isSuperUser;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
