import {
  fetchDealership,
  fetchDealershipWithId,
  createDepartment,
  updateDepartment,
  deleteDepartment,
  updateDealership
} from "../../api/Dealership.js";
import { claimDealer } from "../../api/Auth.js";
import { acceptTnCs } from "../../api/PPSR.js";
import { promote, create, update, setCanRequestPPSR } from "../../api/Dealer.js";
import { axiosInstance } from "../../api/axiosHandler";

const state = {
  dealerships: [],
  currentDealership: null,
  currentDealer: null,
  hasSeenAdvertising_v2: false
};

const getters = {
  currentDealer: state => {
    return state.currentDealer;
  },

  currentDealership: state => {
    return state.currentDealership;
  },

  currentDealers: state => {
    return state.currentDealership.dealers;
  },

  dealerWithURL: (state, getters) => url => {
    return getters.dealers.filter(dealer => dealer.url == url)[0];
  },

  dealershipWithId: (state, getters) => id => {
    return getters.dealerships.filter(dealership => dealership.id == id)[0];
  },

  dealershipWithUrl: (state, getters) => url => {
    return getters.dealerships.filter(dealership => dealership.url == url)[0];
  },

  dealers: state => {
    var dealers = [];
    state.dealerships.forEach(dealership => {
      dealership.dealers.forEach(dealer => {
        dealers.push(dealer);
      });
    });

    return dealers;
  },

  departments: state => {
    var departments = [];
    state.dealerships.forEach(dealership => {
      dealership.departments.forEach(department => {
        department.dealership = dealership.id;
        departments.push(department);
      });
    });

    return departments;
  },

  favouriteResellers: state => {
    return state.currentDealership.favourite_resellers;
  },

  dealerships: state => {
    return state.dealerships;
  },

  hasSeenAdvertisingV2: state => {
    return state.hasSeenAdvertising_v2;
  }
};

const actions = {
  setCurrentDealership(context, dealership) {
    context.commit("SET_CURRENT_DEALERSHIP", dealership);
  },
  setCurrentDealer(context, { dealer, pin }) {
    context.commit("SET_CURRENT_DEALER", dealer);
    this.state.authStore.dealerToken = dealer.id + ":" + pin;
  },
  setCurrentDealerPIN(_, { dealer, pin }) {
    this.state.authStore.dealerToken = dealer.id + ":" + pin;
  },
  activateFavouriteReseller(context, { reseller }) {
    context.commit("ACTIVATE_FAVOURITE_RESELLER", reseller);
  },
  pauseFavouriteReseller(context, { reseller }) {
    context.commit("PAUSE_FAVOURITE_RESELLER", reseller);
  },
  setSeenAdvertisingV2(context) {
    context.commit("SET_SEEN_ADVERTISING_V2");
  },
  fetchDealers(context) {
    axiosInstance()
      .get(this.state.dealershipStore.currentDealership.url + "?fields=dealers")
      .then(response => {
        if (response.data) {
          context.commit("SET_CURRENT_DEALERSHIP_DEALERS", response.data.dealers);
        }
      })
      .catch(error => {
        if (!error.__CANCEL__) {
          context.commit("errorStore/ADD_ERROR", error, { root: true });
        }
      });
  },
  fetchCurrentDealership(context) {
    fetchDealershipWithId(this.state.dealershipStore.currentDealership)
      .then(response => {
        context.commit("SET_CURRENT_DEALERSHIP", response);
      })
      .catch(error => {
        if (!error.__CANCEL__) {
          context.commit("errorStore/ADD_ERROR", error, { root: true });
        }
      });
  },
  fetchDealerships(context) {
    fetchDealership()
      .then(response => {
        context.commit("SET_DEALERSHIPS", response);
      })
      .catch(error => {
        if (!error.__CANCEL__) {
          context.commit("errorStore/ADD_ERROR", error, { root: true });
        }
      });
  },
  updateDealership(context, name) {
    updateDealership(context.state.currentDealership, name)
      .then(() => {
        context.commit("MUTATE_DEALERSHIP_NAME", name);
      })
      .catch(error => {
        context.commit("errorStore/ADD_ERROR", error, { root: true });
      });
  },
  promoteDealer(context, { dealer, isManager }) {
    promote(dealer, isManager)
      .then(response => {
        context.commit("MUTATE_DEALER", response);
      })
      .catch(error => {
        context.commit("errorStore/ADD_ERROR", error, { root: true });
      });
  },
  setCanRequestPPSR(context, { dealer }) {
    setCanRequestPPSR(dealer)
      .then(response => {
        context.commit("MUTATE_DEALER", response);
      })
      .catch(error => {
        context.commit("errorStore/ADD_ERROR", error, { root: true });
      });
  },
  createDealer(context, dealer) {
    create(dealer)
      .then(response => {
        context.commit("ADD_DEALER", response);
      })
      .catch(error => {
        context.commit("errorStore/ADD_ERROR", error, { root: true });
      });
  },
  updateDealer(context, dealer) {
    update(dealer)
      .then(response => {
        context.commit("MUTATE_DEALER", response);
      })
      .catch(error => {
        context.commit("errorStore/ADD_ERROR", error, { root: true });
      });
  },
  claimDealer(context, dealer) {
    claimDealer(dealer)
      .then(response => {
        context.commit("ADD_DEALER", response);
      })
      .catch(error => {
        context.commit("errorStore/ADD_ERROR", error, { root: true });
      });
  },
  createDepartment(context, department) {
    createDepartment(department)
      .then(response => {
        context.commit("ADD_DEPARTMENT", response);
      })
      .catch(error => {
        context.commit("errorStore/ADD_ERROR", error, { root: true });
      });
  },
  updateDepartment(context, { department, title }) {
    updateDepartment(department, title)
      .then(response => {
        context.commit("MUTATE_DEPARTMENT", response);
      })
      .catch(error => {
        context.commit("errorStore/ADD_ERROR", error, { root: true });
      });
  },
  deleteDepartment(context, { department }) {
    deleteDepartment(department)
      .then(() => {
        context.commit("DELETE_DEPARTMENT", department);
      })
      .catch(error => {
        context.commit("errorStore/ADD_ERROR", error, { root: true });
      });
  },
  acceptPPSRTnCs(context) {
    acceptTnCs()
      .then(() => {
        context.commit("ACCEPT_TNC");
      })
      .catch(error => {
        context.commit("errorStore/ADD_ERROR", error, { root: true });
      });
  }
};

const mutations = {
  SET_DEALERSHIPS(state, dealerships) {
    state.dealerships = dealerships;
  },

  SET_CURRENT_DEALERSHIP(state, dealership) {
    state.currentDealership = dealership;
  },

  SET_SEEN_ADVERTISING_V2(state) {
    state.hasSeenAdvertising_v2 = true;
  },

  MUTATE_DEALERSHIP_NAME(state, name) {
    state.currentDealership.first_name = name;
  },

  SET_CURRENT_DEALERSHIP_DEALERS(state, dealers) {
    state.currentDealership.dealers = dealers;
  },

  SET_CURRENT_DEALER(state, dealer) {
    state.currentDealer = dealer;
  },

  MUTATE_DEALER(state, dealer) {
    state.dealerships.forEach(dealership => {
      let index = dealership.dealers.findIndex(dealer1 => dealer1.id == dealer.id);
      if (index != -1) {
        dealership.dealers = dealership.dealers.filter(dealer1 => dealer1.id != dealer.id);
        dealership.dealers.splice(index, 0, dealer);
      }
    });
  },

  ADD_DEALER(state, dealer) {
    state.dealerships.forEach(dealership => {
      if (dealership.id == dealer.dealership) {
        dealership.dealers.unshift(dealer);
      }
    });
  },

  ADD_DEPARTMENT(state, department) {
    state.dealerships.forEach(dealership => {
      if (dealership.id == department.dealership) {
        dealership.departments.push(department);
      }
    });
  },

  MUTATE_DEPARTMENT(state, department) {
    state.dealerships.forEach(dealership => {
      let index = dealership.departments.findIndex(department1 => department1.url == department.url);
      if (index != -1) {
        dealership.departments = dealership.departments.filter(department1 => department1.url != department.url);
        dealership.departments.splice(index, 0, department);
      }
    });
  },

  DELETE_DEPARTMENT(state, department) {
    state.dealerships.forEach(dealership => {
      dealership.departments = dealership.departments.filter(department1 => {
        return department1.url != department.url;
      });
    });
  },

  ACCEPT_TNC(state) {
    state.dealerships.forEach(dealership => {
      dealership.ppsr_permission = true;
      dealership.ppsr_tnc = true;
    });
    state.currentDealership.ppsr_permission = true;
    state.currentDealership.ppsr_tnc = true;
  },

  ACTIVATE_FAVOURITE_RESELLER(state, reseller) {
    state.currentDealership.paused_favourite_resellers = state.currentDealership.paused_favourite_resellers.filter(
      reseller1 => reseller.url != reseller1.url
    );
    state.currentDealership.favourite_resellers.push(reseller);
  },
  PAUSE_FAVOURITE_RESELLER(state, reseller) {
    state.currentDealership.favourite_resellers = state.currentDealership.favourite_resellers.filter(
      reseller1 => reseller.url != reseller1.url
    );
    state.currentDealership.paused_favourite_resellers.push(reseller);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
