import store from "../store";
import axios from "axios";
import VueAxios from "vue-axios";

import { app } from "../main";

function setupAxiosHandler() {
  app.use(VueAxios, axios);
  app.axios.defaults.xsrfCookieName = "csrftoken";
  app.axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
  app.axios.interceptors.request.use(function (config) {
    if (store.state.authStore.authToken) {
      config.headers.Authorization = "Token " + store.state.authStore.authToken;
    }
    if (store.state.authStore.dealerToken) {
      config.headers["current-dealer"] = store.state.authStore.dealerToken;
    }

    return config;
  });
}

function axiosInstance() {
  return app.axios;
}

const hostname = process.env.VUE_APP_HOSTNAME;
// const hostname = "http://192.168.1.4:8000/";
const apiConstants = {
  api_hostname: hostname + "rest-api/",
  api_auth_hostname: hostname + "rest-api-auth/",
  hostname: hostname
};

const ssoConstants = {
  azure: hostname + "accounts/microsoft/login/"
};

const endpoints = {
  dealer: apiConstants.api_hostname + "dealer/",
  dealership: apiConstants.api_hostname + "dealership/",
  department: apiConstants.api_hostname + "department/",
  form: apiConstants.api_hostname + "form/",
  reportFilters: apiConstants.api_hostname + "report-filter/",
  offer: apiConstants.api_hostname + "offer/",
  reports: apiConstants.api_hostname + "reports/",
  tender: apiConstants.api_hostname + "tender/",
  ppsr: apiConstants.api_hostname + "ppsr/",
  reseller: apiConstants.api_hostname + "reseller/",
  marketing: apiConstants.api_hostname + "marketing/",
  marketingMessage: apiConstants.api_hostname + "marketing-message/",
  autograb: apiConstants.api_hostname + "autograb/",
  offsiteForms: apiConstants.api_hostname + "offsite-form/",
  internalDocuments: apiConstants.api_hostname + "internal-document/",
  dealerPack: apiConstants.api_hostname + "dealer-pack/"
};

export { setupAxiosHandler, axiosInstance, apiConstants, ssoConstants, endpoints };
