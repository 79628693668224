import { axiosInstance, endpoints } from "./axiosHandler";

function update(reseller, resellerDict) {
  return new Promise((resolve, reject) => {
    axiosInstance()
      .patch(reseller.url, resellerDict)
      .then(response => {
        resolve(response?.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

function createIndividualReseller(reseller) {
  return new Promise((resolve, reject) => {
    axiosInstance()
      .post(endpoints.reseller + "create-individual-reseller-user/", reseller)
      .then(response => {
        resolve(response?.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

function paymentCheckout() {
  return axiosInstance().get(endpoints.reseller + "payment-checkout/", null);
}

function manageBilling() {
  return axiosInstance().get(endpoints.reseller + "manage-billing/", null);
}

export { update, createIndividualReseller, paymentCheckout, manageBilling };
