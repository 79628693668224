function favouriteOptions() {
  return [
    {
      title: "Favourite",
      value: true
    },
    {
      title: "Not Favourite",
      value: false
    }
  ];
}

function trashOptions() {
  return [
    {
      title: "Trashed",
      value: "True"
    }
  ];
}

function ratingOptions() {
  return [
    {
      title: "1 Star",
      value: 1
    },
    {
      title: "2 Stars",
      value: 2
    },
    {
      title: "3 Stars",
      value: 3
    },
    {
      title: "4 Stars",
      value: 4
    },
    {
      title: "5 Stars",
      value: 5
    }
  ];
}

function tradedStatusOptions() {
  return [
    {
      title: "Traded",
      value: 2
    },
    {
      title: "Not Traded",
      value: 1
    },
    {
      title: "Not Traded, Not Lost",
      value: 4
    },
    {
      title: "Lost",
      value: 3
    }
  ];
}

function appraisalStatusOptions() {
  return [
    {
      title: "Pending",
      value: "Pending"
    },
    {
      title: "Active",
      value: "Active"
    },
    {
      title: "Complete",
      value: "Complete"
    }
  ];
}

function arrivedStatusOptions() {
  return [
    {
      title: "Arrived",
      value: true
    },
    {
      title: "Pending Arrival",
      value: false
    }
  ];
}

function intentionStatusOptions() {
  return [
    {
      title: "Wholesale",
      value: 0
    },
    {
      title: "Retail",
      value: 1
    },
    {
      title: "Undecided",
      value: 2
    },
    {
      title: "Budget",
      value: 3
    }
  ];
}

function readyForTradeOptions() {
  return [
    {
      title: "Ready",
      value: "True"
    },
    {
      title: "Not Ready",
      value: "False"
    }
  ];
}

function acceptedMarketingOptions() {
  return [
    {
      title: "Accepted",
      value: "True"
    },
    {
      title: "Denied",
      value: "False"
    }
  ];
}

const filterOptions = [
  {
    key: "car_registration_number",
    title: "Rego"
  },
  {
    key: "car_make",
    title: "Make"
  },
  {
    key: "car_model",
    title: "Model"
  },
  {
    key: "car_line",
    title: "Line"
  },
  {
    key: "car_chassis_number",
    title: "VIN"
  },
  {
    key: "car_colour",
    title: "Colour"
  },
  {
    key: "customer_name",
    title: "Customer Name"
  },
  {
    key: "car_model_year_gt",
    title: "Model Year >",
    type: "number"
  },
  {
    key: "car_model_year_lt",
    title: "Model Year <",
    type: "number"
  },
  {
    key: "car_mileage_gt",
    title: "Mileage >",
    type: "number"
  },
  {
    key: "car_mileage_lt",
    title: "Mileage <",
    type: "number"
  }
];

import { reasonsLost } from "./formHelpers";

function calculateExtraOptionsForFilters(
  dealerships,
  selectedDealership,
  dealers,
  includeDealership = true,
  includeDepartment = true,
  includeFavourite = true,
  includeReasonsLost = true,
  includeTrash = true,
  includeStars = true,
  includeDealer = true,
  includeTraded = true,
  includeFormStatus = true,
  includeArrival = true,
  includeIntention = true,
  includeReadyForTrade = true,
  includeAcceptedRemarketing = false
) {
  var options = [];

  if (includeDealership) {
    let dealershipOptions = [];
    dealerships.forEach(dealership => {
      dealershipOptions.push({
        title: dealership.first_name,
        value: dealership.id
      });
    });

    options.push({
      key: "dealership",
      title: "Dealership",
      children: dealershipOptions
    });

    options.push({
      key: "dealership_not",
      title: "Dealership Not",
      children: dealershipOptions
    });
  }

  if (selectedDealership) {
    let departmentOptions = [];
    let filteredDealership = dealerships.find(dealership => {
      return dealership.id == selectedDealership;
    });
    if (filteredDealership) {
      filteredDealership.departments.forEach(department => {
        departmentOptions.push({
          title: department.title,
          value: department.id
        });
      });

      options.push({
        key: "department",
        title: "Department",
        children: departmentOptions
      });

      options.push({
        key: "department_not",
        title: "Department Not",
        children: departmentOptions
      });
    }
  } else if (dealerships.length == 1) {
    let departmentOptions = [];
    dealerships[0].departments.forEach(department => {
      departmentOptions.push({
        title: department.title,
        value: department.id
      });
    });

    options.push({
      key: "department",
      title: "Department",
      children: departmentOptions
    });

    options.push({
      key: "department_not",
      title: "Department Not",
      children: departmentOptions
    });
  } else if (includeDepartment) {
    let departmentOptions = [];
    dealerships.forEach(dealership => {
      dealership.departments.forEach(department => {
        departmentOptions.push({
          title: department.title,
          value: department.id
        });
      });
    });
    options.push({
      key: "department",
      title: "Department",
      children: departmentOptions
    });

    options.push({
      key: "department_not",
      title: "Department Not",
      children: departmentOptions
    });
  }

  if (includeDealer) {
    let dealerOptions = [];
    dealers.forEach(dealer => {
      if ((!selectedDealership || selectedDealership == dealer.dealership) && dealer.is_active) {
        dealerOptions.push({
          title: dealer.first_name + " " + dealer.last_name,
          value: dealer.id
        });
      }
    });

    options.push({
      key: "starting_dealer",
      title: "Started By",
      children: dealerOptions
    });

    options.push({
      key: "dealer",
      title: "Completed By",
      children: dealerOptions
    });
  }

  if (includeFavourite) {
    options.push({
      key: "is_favourite",
      title: "Favourite",
      children: favouriteOptions()
    });
  }

  if (includeReasonsLost) {
    options.push({
      key: "reasons_lost",
      title: "Lost",
      children: reasonsLost.map(rl => {
        return { title: rl, value: rl };
      })
    });
  }

  if (includeTrash) {
    options.push({
      key: "is_trash",
      title: "Trashed",
      children: trashOptions()
    });
  }

  if (includeStars) {
    options.push({
      key: "vehicle_rating",
      title: "Star Rating",
      children: ratingOptions()
    });
  }

  if (includeTraded) {
    options.push({
      key: "traded_status",
      title: "Traded Status",
      children: tradedStatusOptions()
    });
  }

  if (includeFormStatus) {
    options.push({
      key: "appraisal_status",
      title: "Appraisal Status",
      children: appraisalStatusOptions()
    });
  }

  if (includeArrival) {
    options.push({
      key: "traded_arrived",
      title: "Arrived",
      children: arrivedStatusOptions()
    });
  }

  if (includeIntention) {
    options.push({
      key: "traded_purpose",
      title: "Intention of Trade",
      children: intentionStatusOptions()
    });
  }

  if (includeReadyForTrade) {
    options.push({
      key: "ready_for_traded",
      title: "Ready For Trade",
      children: readyForTradeOptions()
    });
  }

  if (includeAcceptedRemarketing) {
    options.push({
      key: "customer_remarketing_accepted",
      title: "Customer Accepted\nRemarketing",
      children: acceptedMarketingOptions()
    });
  }

  return options;
}

function calculateFilters(filters, dealerships, dealers) {
  var calculatedFilters = [];
  for (const [key, value] of Object.entries(filters)) {
    var filter = filterOptions
      .concat(
        calculateExtraOptionsForFilters(
          dealerships,
          null,
          dealers,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true,
          true
        )
      )
      .find(option => {
        return option.key == key;
      });
    if (filter) {
      switch (key) {
        case "dealership":
          {
            let dealership = dealerships.filter(dealership => dealership.id == value)[0];
            filter.value = dealership ? dealership.first_name : value;
          }
          break;
        case "dealership_not":
          {
            let dealership = dealerships.filter(dealership => dealership.id == value)[0];
            filter.value = dealership ? dealership.first_name : value;
          }
          break;
        case "department":
          {
            let department;
            dealerships.forEach(dealership => {
              let departmentSearch = dealership.departments.find(dep => {
                return dep.id == value;
              });
              if (departmentSearch) {
                department = departmentSearch;
              }
            });
            filter.value = department ? department.title : value;
          }
          break;
        case "department_not":
          {
            let department;
            dealerships.forEach(dealership => {
              let departmentSearch = dealership.departments.find(dep => {
                return dep.id == value;
              });
              if (departmentSearch) {
                department = departmentSearch;
              }
            });
            filter.value = department ? department.title : value;
          }
          break;
        case "dealer":
          {
            let dealer = dealers.filter(dealer => dealer.id == value)[0];
            filter.value = dealer ? dealer.first_name + " " + dealer.last_name : value;
          }
          break;
        case "starting_dealer":
          {
            let dealer = dealers.filter(dealer => dealer.id == value)[0];
            filter.value = dealer ? dealer.first_name + " " + dealer.last_name : value;
          }
          break;
        case "is_favourite":
          {
            let status = favouriteOptions().filter(status => status.value == value)[0];
            filter.value = status ? status.title : value;
          }
          break;
        case "is_trash":
          {
            let status = trashOptions().filter(status => status.value == value)[0];
            filter.value = status ? status.title : value;
          }
          break;
        case "vehicle_rating":
          {
            let rating = ratingOptions().filter(rating => rating.value == value)[0];
            filter.value = rating ? rating.title : value;
          }
          break;
        case "traded_status":
          {
            let status = tradedStatusOptions().filter(status => status.value == value)[0];
            filter.value = status ? status.title : value;
          }
          break;
        case "traded_arrived":
          {
            let status = arrivedStatusOptions().filter(status => status.value == value)[0];
            filter.value = status ? status.title : value;
          }
          break;
        case "traded_purpose":
          {
            let status = intentionStatusOptions().filter(status => status.value == value)[0];
            filter.value = status ? status.title : value;
          }
          break;

        case "ready_for_traded":
          {
            let status = readyForTradeOptions().filter(status => status.value == value)[0];

            filter.value = status ? status.title : value;
          }
          break;
        case "customer_remarketing_accepted":
          {
            let status = acceptedMarketingOptions().filter(status => status.value == value)[0];

            filter.value = status ? status.title : value;
          }
          break;

        default:
          filter.value = value;
          break;
      }

      calculatedFilters.push(filter);
    }
  }
  return calculatedFilters;
}

export {
  tradedStatusOptions,
  appraisalStatusOptions,
  arrivedStatusOptions,
  intentionStatusOptions,
  calculateFilters,
  calculateExtraOptionsForFilters,
  filterOptions
};
